import React, { Suspense, useEffect, useState } from 'react'
import { HashRouter, Route, Routes, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { CSpinner, useColorModes } from '@coreui/react'
import './scss/style.scss'
import Mainpage from './MainPage/Mainpage'
import useApiManager from './ApiManager'
import { ToastContainer } from 'react-toastify'

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Contact = React.lazy(() => import('./NavigationBar/Contact'))
const Profile = React.lazy(() => import('./NavigationBar/Profile'))
const MainPage = React.lazy(() => import('./MainPage/Mainpage'))

const Projects = React.lazy(() => import('./NavigationBar/Projects'))
const Services = React.lazy(() => import('./NavigationBar/Services'))
// const Gallary = React.lazy(() => import('./NavigationBar/Gallary'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const [userMenuList, setUserMenuList] = useState([])
  const { postRequest, formData, setFormData, getRequest } = useApiManager()
  const { isColorModeSet, setColorMode } = useColorModes('coreui-free-react-admin-template-theme')
  const storedTheme = useSelector((state) => state.theme)

  const storedRoleOnchange = localStorage.getItem('onRoleChange')
  const dispatch = useDispatch()
  const userDataString = sessionStorage.getItem('userData')
  const userData = userDataString ? JSON.parse(userDataString) : null
  console.warn('app', storedRoleOnchange)
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.href.split('?')[1])
    const theme = urlParams.get('theme') && urlParams.get('theme').match(/^[A-Za-z0-9\s]+/)[0]
    if (theme) {
      setColorMode(theme)
    }

    if (isColorModeSet()) {
      return
    }

    setColorMode(storedTheme)
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    fetchMenuList()
  }, [])
  const fetchMenuList = async () => {
    try {
      const result = await getRequest('/getAllMenuSubmenubyUSer', formData, false, userData[0]?.nid)
      if (result.IsSuccess) {
        setUserMenuList(result.data)
        dispatch({ type: 'set', userRoleData: result.data })
        localStorage.setItem('role', JSON.stringify(result.data))
      } else {
        console.error(result.message)
      }
    } catch (error) {
      console.error('Error submitting form:', error)
    }
  }
  return (
    <HashRouter>
      <ToastContainer />
      <Suspense
        fallback={
          <div className="pt-3 text-center">
            <CSpinner color="primary" variant="grow" />
          </div>
        }
      >
        <Routes>
          <Route exact path="/" name="Main Page" element={<MainPage />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/contact" name="Contact Page" element={<Contact />} />
          <Route exact path="/profile" name="Profile Page" element={<Profile />} />
          <Route exact path="/project" name="Project Page" element={<Projects />} />
          <Route exact path="/services" name="Service Page" element={<Services />} />
          {/* <Route exact path="/gallary" name="Login Page" element={<Gallary />} /> */}
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route path="*" name="Home" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </HashRouter>
  )
}

export default App
